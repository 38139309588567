import * as React from "react";
import Seo from "../components/Seo/seo";

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404" description="Nothing found page" />
      <h1>Not Found</h1>
    </>
  );
};

export default NotFoundPage;
